export default function Video() {
    return (
        <>
            <div className="flex flex-col-reverse xl:flex-row justify-between items-center xl:mx-16">
                <div className="mx-auto xl:mb-32">
                    <button className="text-white px-6 md:px-16 text-2xl mb-10 shadow-md shadow-black uppercase py-4 md:py-6 rounded-2xl bg-[#E7513E] transition duration-300 ease-in-out hover:scale-105">
                        <a
                            href="/presentation">
                            Mes Premiers Activités
                        </a>
                    </button>
                </div>
                <video
                    className="drop-shadow-2xl mb-12 border-[10px] mt-16 md:mt-0 border-white w-[23rem] sm:w-[29rem] h-[20rem] md:w-[695px] md:h-[400px] xl:w-[963px] xl:h-[550px]"
                    controls loop muted autoPlay controls=''>
                    <source src="presentation.mp4" type="video/mp4" />
                </video>
                {/* <iframe
                    className="drop-shadow-2xl mb-12 border-[10px] mt-16 md:mt-0 border-white w-[23rem] sm:w-[29rem] h-[20rem] md:w-[695px] md:h-[400px] xl:w-[963px] xl:h-[550px]"
                    src={`https://www.youtube.com/embed/mtsgwrHOets`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                /> */}
            </div>
        </>
    )
}