import Header from "../Homepage/Header";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";

const Presentation = () => {
    return (
        <div className="overflow-x-hidden">
            <Header />
            <div className="bg-background bg-cover bg-center px-14">
                <Navbar />
                <div className="w-11/12 xl:w-2/4 text-white font-bold h-[1000px] text-left mx-auto pt-12">
                    <div className="gradiant rouded rounded-md p-12 shadow-2xl">
                        <p className="text-xl md:text-2xl lg:text-3xl xl:text-6xl 2xl:6xl text-center mb-3">
                            Mes pemières activités	أنشطتي اليومية
                        </p>
                        <p className="text-sm md:text-base lg:text-xl xl:text-4xl 2xl:4xl text-right" dir="rtl">أصدرت مؤسسة دار المسار منذ عدة سنوات مجموعة تربوية متكاملة تتمثل في: أنشطتي اليومية باللغة العربية و Mes pemi&egrave;res activit&eacute;s باللغة الفرنسية. و قد صادقت عليهما وزارة التربية الوطنية بتاريخ 23 يوليوز 2020 تحت رقم Presco 021220 وفقا للاطار المنهاجي الوطني للتعليم الأولي.</p>
                        {/* <p className="text-xl md:text-2xl lg:text-3xl xl:text-6xl 2xl:6xl text-right mb-3 mt-6">
                            أنشطتي اليومية
                        </p> */}
                        <p className="text-sm md:text-base lg:text-xl xl:text-4xl 2xl:4xl text-right" dir="rtl">
                            هي مجموعة تربوية شاملة خاصة بأطفال التعليم الأولي بالمستوى الأول (4-5 سنوات) و بالمستوى الثاني ( 5-6 سنوات)، تعتمد  المشروع التربوي  كمقاربة للعمل مع الأطفال، وتتمحور حول 6 مجالات للتعلم و هي:
                        </p>
                        <ol className="text-sm md:text-base lg:text-xl xl:text-4xl 2xl:4xl text-right list-decimal py-6 px-12" dir="rtl">
                            <li>
                                استكشاف الذات والمحيط
                            </li>
                            <li>
                                تنظيم التفكير
                            </li>
                            <li>
                                التعبير اللغوي و التواصل ( التواصل الشفهي، القراءة، الكتابة)
                            </li>
                            <li>السلوك الحسي الحركي</li>
                            <li>
                                التعبير الفني و الجمالي
                            </li>
                            <li>
                                القيم و قواعد العيش المشترك
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Presentation;