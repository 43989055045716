import { MailOpenIcon, PhoneIcon } from '@heroicons/react/outline'

export default function Header() {
    return (
        <div className="h-12 bg-[#343E49] flex text-white justify-between px-6 xl:px-0 xl:justify-start xl:gap-40 xl:pl-96">
            <div className='flex items-center gap-3'>
                <MailOpenIcon className='w-5' />
                <p>edit.22@editconsulting.ma</p>
            </div>
            <div className='flex items-center gap-3'>
                <PhoneIcon className='w-5' />
                <p>+(212) 0522296018/19</p>
            </div>
        </div>

    )
}