import React from 'react'
import Header from '../Homepage/Header'
import Navbar from '../Homepage/Navbar'
import Footer from '../Homepage/Footer'

function EspaceEducateur() {
    return (
        <div className="overflow-x-hidden">
            <Header />
            <div className="bg-center bg-cover bg-background h-[3100px] md:h-[2000px] xl:h-[1400px] px-14">
                <Navbar />
                <div className="w-11/12 xl:w-2/3 text-white font-bold mt-12 h-[500px] text-left mx-auto">
                    <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                        TÉLÉCHARGEMENT FICHES TECHNIQUES تحميل
                    </p>
                    <div className="grid grid-cols-1 gap-10 mt-12 md:grid-cols-2 xl:grid-cols-3 md:mx-6">
                        <div className="justify-center bg-[#00B0DE] hover:bg-[#3ea1c2] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a
                                href="/pdfmpa34.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">Carte d'activité Petite Section</h5>
                            </a>
                        </div>
                        <div className="justify-center bg-[#EB8B2E] hover:bg-[#dd7f22] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a
                                href="/pdfca1.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">Carte d'activité Moyenne Section</h5>
                            </a>
                        </div>
                        <div className="justify-center bg-[#00B0DE] hover:bg-[#3ea1c2] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a href="/pdfca2.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">Carte d'activité Grande Section</h5>
                            </a>
                        </div>
                        <div className="justify-center bg-[#EB8B2E] hover:bg-[#dd7f22] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a href="/pdfn256F.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">Guide pédagogique
                                    de la deuxième année de l’enseignement
                                    préscolaire</h5>
                            </a>
                        </div>
                        <div className="justify-center bg-[#00B0DE] hover:bg-[#3ea1c2] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a
                                href="/pdfa45.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">الدليل البيداغوجي  للمربيات المستوى الأول </h5>
                            </a>
                        </div>
                        <div className="justify-center bg-[#EB8B2E] hover:bg-[#dd7f22] hover:scale-105 hover:duration-500 hover:ease-in-out font-bold rounded-xl shadow-2xl py-2 px-6 inline-flex items-center">
                            <a href="/pdfa56.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">الدليل البيداغوجي  للمربيات المستوى الثاني </h5>
                            </a>
                        </div>
                        <div className="bg-[#00B0DE] shadow-xl rounded-lg group hover:bg-[#3ea1c2] transition duration-700 ease-in-out hover:scale-105">
                            <a href="/pdfj1.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">جدادات المستوى الاول</h5>
                            </a>
                        </div>
                        <div className="bg-[#EB8B2E] shadow-xl rounded-lg group hover:bg-[#dd7f22] transition duration-700 ease-in-out hover:scale-105">
                            <a href="/pdfj2.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">جدادات المستوى الثاني</h5>
                            </a>
                        </div>
                        <div className="bg-[#00B0DE] shadow-xl rounded-lg group hover:bg-[#3ea1c2] transition duration-700 ease-in-out hover:scale-105">
                            <a href="/caa34.pdf">
                                <h5 className="flex items-center justify-center w-full h-48 text-xl text-left text-white transition ease-in-out group-hover:text-white duration-250">بطاقات الأنشطة</h5>
                            </a>
                        </div>
                    </div>
                    <p className="mt-12 text-xl text-black md:text-2xl lg:text-3xl xl:text-4xl">
                        Téléchargement تحميل المذكرة اليومية
                    </p>
                    <div className="grid grid-cols-1 gap-10 mt-12 md:grid-cols-2 xl:grid-cols-3 md:mx-6">
                        <div className="bg-[#00B0DE] black shadow-xl rounded-lg group hover:bg-[#3ea1c2] transition duration-700 ease-in-out hover:scale-105">
                            <a
                                href="/pdfm45.pdf">
                                <div className='flex flex-col items-center justify-center h-48 mx-auto text-white'>
                                    <h5 className="w-full mb-2 text-xl text-center transition ease-in-out group-hover:text-white duration-250">مذكرتي اليومية 5-4 سنوات
                                    </h5>
                                    <h5 className='px-2 py-1 text-lg font-bold text-gray-800 bg-white rounded'>فرنسية عربية</h5>
                                </div>    </a>
                        </div>
                        <div className="bg-[#EB8B2E] shadow-xl rounded-lg group hover:bg-[#dd7f22] transition duration-700 ease-in-out hover:scale-105">
                            <a href="/pdfm56.pdf">
                                <div className='flex flex-col items-center justify-center h-48 mx-auto text-white'>
                                    <h5 className="w-full mb-2 text-xl text-center transition ease-in-out group-hover:text-white duration-250">مذكرتي اليومية 6-5 سنوات
                                    </h5>
                                    <h5 className='px-2 py-1 text-lg font-bold text-gray-800 bg-white rounded'>فرنسية عربية</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EspaceEducateur