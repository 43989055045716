import React, { useState } from 'react'
import { fadeInRight, flipInY } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import Math1 from './Math1';
import Math2 from './Math2';
import Math3 from './Math3';
import Math4 from './Math4';
import Arabe31 from './Arabe31';
import Arabe41 from './Arabe41';
import Arabe32 from './Arabe32';
import Arabe52 from './Arabe52';
import Arabe51 from './Arabe51';
import Arabe42 from './Arabe42';
import Arabe62 from './Arabe62';
import Arabe61 from './Arabe61';
import Islam from './Islam';


export default function ProductsList() {

    const styles = {
        bounce: {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInRight, 'bounce')
        },
        flip: {
            animation: 'x 1s',
            animationName: Radium.keyframes(flipInY, 'flip')
        },
        flip2: {
            animation: 'x 1s',
            animationName: Radium.keyframes(flipInY, 'flip2')
        }
    }

    const [checked, setChecked] = useState(false)

    function handleChange() {
        setChecked(!checked)
    }


    return (
        <>
            <div className="flex justify-center">
                <span className="ml-3 text-sm font-medium text-white">Français</span>
                <label className="relative inline-flex items-center mx-6 cursor-pointer">
                    <input checked={checked} type="checkbox" className="sr-only peer" onChange={handleChange} />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-700">
                    </div>
                </label>
                <span className="ml-3 text-sm font-medium text-white">العربية</span>
            </div>
            {!checked ? (
                <>
                    <h1 className='gap-10 pt-12 text-4xl text-left text-white md:mx-6 xl:mx-60'>MATHS+</h1>
                    <StyleRoot style={styles.bounce}>
                        <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                            <div className="relative">
                                <Math1 />
                                <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">65,00<br></br>DHs</div>
                            </div>
                            <div className="relative">
                                <Math3 />
                                <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">65,00<br></br>DHs</div>
                            </div>
                            <div className="relative">
                                <Math4 />
                                <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00de0b] rounded-full text-white text-xl font-bold pt-4">65,00<br></br>DHs</div>
                            </div>
                            <div className="relative">
                                <Math2 />
                                <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">65,00<br></br>DHs</div>
                            </div>
                        </div>
                    </StyleRoot>
                </>
            )
                :
                (
                    <>
                        <h1 className='gap-10 pt-12 text-4xl text-right text-white md:mx-6 xl:mx-60'>المسار في اللغة العربية</h1>
                        <StyleRoot style={styles.bounce}>
                            <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                <div className="relative">
                                    <Arabe41 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe32 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe31 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe52 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00de0b] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe51 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#de0030] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe42 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#de5900] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="sm:hidden xl:block"></div>
                                <div className="relative">
                                    <Arabe62 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#dade00] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                                <div className="relative">
                                    <Arabe61 />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#aa00de] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                </div>
                            </div>
                        </StyleRoot>
                        <h1 className='gap-10 pt-12 text-4xl text-right text-white md:mx-6 xl:mx-60'>الجديد التربية الإسلامية</h1>
                        <StyleRoot style={styles.bounce}>
                            <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                <div className="sm:hidden xl:block"></div>
                                <div className="sm:hidden xl:block"></div>
                                <div className="relative">
                                    <Islam />
                                    <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">14,00<br></br>DHs</div>
                                </div>
                            </div>
                        </StyleRoot>
                    </>
                )
            }

        </>
    )
}
