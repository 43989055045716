import logo from './logo.svg';
import './App.css';
import Homepage from './Homepage';
import Prescolaire from './Prescolaire';
import A_Moi_D_Ecrire from './A_moi_decrire';
import MPA145 from './MPA145';
import MPA245 from './MPA245';
import MPA345 from './MPA345';
import MPA156 from './MPA156';
import MPA256 from './MPA256';
import MPA356 from './MPA356';
import Cahier_dactivité_MPA_56 from './cahier_d\'activité_MPA_56';
import { Routes, Route, Link } from "react-router-dom";
import MPA134 from './MPA134';
import MPA234 from './MPA234';
import MPA334 from './MPA334';
import CAMPA45 from './CAMPA45';
import CAMPA56 from './CAMPA56';
import ANCHITATI1 from './ANCHITATI1';
import ANCHITATI2 from './ANCHITATI2';
import ANCHITATI3 from './ANCHITATI3';
import ANCHITATI4 from './ANCHITATI4';
import ANCHITATI5 from './ANCHITATI5';
import ANCHITATI6 from './ANCHITATI6';
import ANCHITATI7 from './ANCHITATI7';
import ANCHITATI8 from './ANCHITATI8';
import ANCHITATI9 from './ANCHITATI9';
import ANCHITATI10 from './ANCHITATI10';
import KORASSA1 from './KORASSA1';
import PN1 from './PN1';
import PN2 from './PN2';
import PN3 from './PN3';
import PN4 from './PN4';
import PN5 from './PN5';
import PN6 from './PN6';
import PN7 from './PN7';
import PN8 from './PN8';
import PN9 from './PN9';
import PN10 from './PN10';
import Presentation from './Presentation';
import QuiSommesNous from './QuiSommesNous';
import EspaceEducateur from './EspaceEducateur';
import RecourcesPedagogiques from './RecourcesPedagogiques';
import Primaire from './Primaire';
import Math1 from './Primaire/Math1/'
import Math3 from './Primaire/Math3/';
import Math4 from './Primaire/Math4/';
import Math2 from './Primaire/Math2/'
import Arabe31 from './Primaire/Arabe31/';
import Arabe32 from './Primaire/Arabe32/';
import Arabe41 from './Primaire/Arabe41/';
import Arabe42 from './Primaire/Arabe42/';
import Arabe51 from './Primaire/Arabe51/';
import Arabe52 from './Primaire/Arabe52/';
import Arabe61 from './Primaire/Arabe61/';
import Arabe62 from './Primaire/Arabe62/';
import Islam from './Primaire/Islam/';
import ANCHITATI11 from './ANCHITATI11';
import ANCHITATI12 from './ANCHITATI12';
import ANCHITATI13 from './ANCHITATI13';
import ANCHITATI14 from './ANCHITATI14';
import ANCHITATI15 from './ANCHITATI15';
import KORASSA2 from './KORASSA2';







function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/prescolaire" element={<Prescolaire />} />
        <Route path="/primaire" element={<Primaire />} />
        <Route path="/a-moi-decrire" element={<A_Moi_D_Ecrire />} />
        <Route path="/mpa134" element={<MPA134 />} />
        <Route path="/mpa234" element={<MPA234 />} />
        <Route path="/mpa334" element={<MPA334 />} />
        <Route path="/mpa145" element={<MPA145 />} />
        <Route path="/mpa245" element={<MPA245 />} />
        <Route path="/mpa345" element={<MPA345 />} />
        <Route path="/mpa156" element={<MPA156 />} />
        <Route path="/mpa256" element={<MPA256 />} />
        <Route path="/mpa356" element={<MPA356 />} />
        <Route path="/campa45" element={<CAMPA45 />} />
        <Route path="/campa56" element={<CAMPA56 />} />
        <Route path="/cahier-deactivite-MPA-56" element={<Cahier_dactivité_MPA_56 />} />
        <Route path="/anchitati1" element={<ANCHITATI1 />} />
        <Route path="/anchitati2" element={<ANCHITATI2 />} />
        <Route path="/anchitati3" element={<ANCHITATI3 />} />
        <Route path="/anchitati4" element={<ANCHITATI4 />} />
        <Route path="/anchitati5" element={<ANCHITATI5 />} />
        <Route path="/anchitati6" element={<ANCHITATI6 />} />
        <Route path="/anchitati7" element={<ANCHITATI7 />} />
        <Route path="/anchitati8" element={<ANCHITATI8 />} />
        <Route path="/anchitati9" element={<ANCHITATI9 />} />
        <Route path="/anchitati10" element={<ANCHITATI10 />} />
        <Route path="/anchitati11" element={<ANCHITATI11 />} />
        <Route path="/anchitati12" element={<ANCHITATI12 />} />
        <Route path="/korassa1" element={<KORASSA1 />} />
        <Route path="/anchitati13" element={<ANCHITATI13 />} />
        <Route path="/anchitati14" element={<ANCHITATI14 />} />
        <Route path="/anchitati15" element={<ANCHITATI15 />} />
        <Route path="/korassa2" element={<KORASSA2 />} />
        <Route path="/pn1" element={<PN1 />} />
        <Route path="/pn2" element={<PN2 />} />
        <Route path="/pn3" element={<PN3 />} />
        <Route path="/pn4" element={<PN4 />} />
        <Route path="/pn5" element={<PN5 />} />
        <Route path="/pn6" element={<PN6 />} />
        <Route path="/pn7" element={<PN7 />} />
        <Route path="/pn8" element={<PN8 />} />
        <Route path="/pn9" element={<PN9 />} />
        <Route path="/pn10" element={<PN10 />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/quisommesnous" element={<QuiSommesNous />} />
        <Route path="/espaceeducateur" element={<EspaceEducateur />} />
        <Route path="/recourcespedagogiques" element={<RecourcesPedagogiques />} />
        <Route path="/math1" element={<Math1 />} />
        <Route path="/math2" element={<Math3 />} />
        <Route path="/math3" element={<Math4 />} />
        <Route path="/math4" element={<Math2 />} />
        <Route path="/arabe31" element={<Arabe31 />} />
        <Route path="/arabe32" element={<Arabe32 />} />
        <Route path="/arabe41" element={<Arabe41 />} />
        <Route path="/arabe42" element={<Arabe42 />} />
        <Route path="/arabe51" element={<Arabe51 />} />
        <Route path="/arabe52" element={<Arabe52 />} />
        <Route path="/arabe61" element={<Arabe61 />} />
        <Route path="/arabe62" element={<Arabe62 />} />
        <Route path="/islam" element={<Islam />} />
      </Routes>
    </div>
  );
}

export default App;
