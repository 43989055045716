import MPA145 from "./MPA145"
import MPA245 from "./MPA245"
import MPA345 from "./MPA345"
import MPA156 from "./MPA156"
import MPA256 from "./MPA256"
import MPA356 from "./MPA356"
import MPA134 from "./MPA134"
import MPA234 from "./MPA234"
import MPA334 from "./MPA334"
import CAMPA45 from "./CAMPA45"
import CAMPA56 from "./CAMPA56"
import { useEffect, useState } from "react"
import ANCHITATI1 from "./ANCHITATI1"
import ANCHITATI2 from "./ANCHITATI2"
import ANCHITATI3 from "./ANCHITATI3"
import ANCHITATI4 from "./ANCHITATI4"
import ANCHITATI5 from "./ANCHITATI5"
import ANCHITATI6 from "./ANCHITATI6"
import ANCHITATI9 from "./ANCHITATI9"
import ANCHITATI8 from "./ANCHITATI8"
import ANCHITATI7 from "./ANCHITATI7"
import PN1 from "./PN1"
import PN3 from "./PN3"
import PN2 from "./PN2"
import PN6 from "./PN6"
import PN5 from "./PN5"
import PN4 from "./PN4"
import PN9 from "./PN9"
import PN8 from "./PN8"
import PN7 from "./PN7"
import PN10 from "./PN10"

import { fadeInRight, flipInY } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import ANCHITATI10 from "./ANCHITATI10"
import ANCHITATI11 from "./ANCHITATI11"
import ANCHITATI12 from "./ANCHITATI12"
import ANCHITATI13 from "./ANCHITATI13"
import ANCHITATI14 from "./ANCHITATI14"
import ANCHITATI15 from "./ANCHITATI15"
import KORASSA1 from "./KORASSA1"
import KORASSA2 from "./KORASSA2"


const styles = {
    bounce: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInRight, 'bounce')
    },
    flip: {
        animation: 'x 1s',
        animationName: Radium.keyframes(flipInY, 'flip')
    },
    flip2: {
        animation: 'x 1s',
        animationName: Radium.keyframes(flipInY, 'flip2')
    }
}

export default function ProductsList() {

    const [checked, setChecked] = useState(false)
    const [selected, setSelected] = useState("Petite Section")

    function handleChange() {
        setChecked(!checked)
    }

    useEffect(() => {
        setChecked(false)
    }, [])

    return (
        <>
            <div className="flex justify-center">
                <span className="ml-3 text-sm font-medium text-white">Français</span>
                <label className="relative inline-flex items-center mx-6 cursor-pointer">
                    <input checked={checked} type="checkbox" className="sr-only peer" onChange={handleChange} />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-700">
                    </div>
                </label>
                <span className="ml-3 text-sm font-medium text-white">العربية</span>
            </div>
            {!checked ?
                <>
                    <StyleRoot style={styles.flip}>
                        <div className="flex flex-wrap justify-center mt-10 space-x-4 space-y-4">
                            <button
                                onClick={() => setSelected("Petite Section")}
                                className="ml-4 mt-4 w-[190px] justify-center bg-[#1AB2AD] hover:bg-[#11a096] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                Petite Section
                            </button>
                            <button
                                onClick={() => setSelected("Moyenne Section")}
                                className="w-[190px] justify-center bg-[#EB8B2E] hover:bg-[#dd7f22] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                Moyenne Section
                            </button>
                            <button
                                onClick={() => setSelected("Grande Section")}
                                className="w-[190px] justify-center bg-[#00B0DE] hover:bg-[#3ea1c2] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                Grande Section
                            </button>
                            <button
                                onClick={() => setSelected("Histoires")}
                                className="w-[190px] justify-center bg-[#986C9F] hover:bg-[#7b5281] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                Histoires
                            </button>
                            <button
                                onClick={() => setSelected("Coloriage")}
                                className="w-[190px] justify-center bg-[#DA7E81] hover:bg-[#c2676a] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                Coloriage
                            </button>
                        </div>
                    </StyleRoot>
                    {selected === "Petite Section" ?
                        <>
                            <h1 className='gap-10 pt-12 text-4xl text-left text-white md:mx-6 xl:mx-60'>Mes premières activitès</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <MPA134 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA234 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA334 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Moyenne Section" ?
                        <>
                            <h1 className='gap-10 pt-12 text-4xl text-left text-white md:mx-6 xl:mx-60'>Mes premières activitès</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <MPA145 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#50B9E8] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA245 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#7ABBA6] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA345 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#A6C65E] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                            <h1 className='gap-10 text-4xl text-left text-white md:mx-6 xl:mx-60'>Le cahier d'activités</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <CAMPA45 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#72D0F6] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Grande Section" ?
                        <>
                            <h1 className='gap-10 pt-12 text-4xl text-left text-white md:mx-6 xl:mx-60'>Mes premières activitès</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <MPA156 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#E09048] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA256 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#986C9F] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <MPA356 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#DA7E81] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                            <h1 className='gap-10 text-4xl text-left text-white md:mx-6 xl:mx-60'>Le cahier d'activités</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <CAMPA56 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#cc0613] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Histoires" ?
                        <img src="coming-soon.png" alt="comingsoon" className="w-1/2 mx-auto" />
                        : null}
                    {selected === "Coloriage" ?
                        <>
                            <h1 className='gap-10 mt-6 text-4xl text-left text-white md:mx-6 xl:mx-60'>Le petit novateur</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <PN3 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN2 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN1 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN6 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN5 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN4 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN9 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN8 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN7 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="relative">
                                        <PN10 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                </>
                :
                <>
                    <StyleRoot style={styles.flip2}>
                        <div className="flex flex-wrap justify-center mt-10 space-x-4 space-y-4">
                            <button
                                onClick={() => setSelected("Coloriage")}
                                className="ml-4 mt-4 w-[190px] justify-center bg-[#DA7E81] hover:bg-[#c2676a] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                تلوين
                            </button>
                            <button
                                onClick={() => setSelected("Histoires")}
                                className="w-[190px] justify-center bg-[#986C9F] hover:bg-[#7b5281] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                قصص
                            </button>


                            <button
                                onClick={() => setSelected("Grande Section")}
                                className="w-[190px] justify-center bg-[#00B0DE] hover:bg-[#3ea1c2] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                المستوى الثاني
                            </button>
                            <button
                                onClick={() => setSelected("Moyenne Section")}
                                className="w-[190px] justify-center bg-[#EB8B2E] hover:bg-[#dd7f22] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                المستوى الأول
                            </button>
                            <button
                                onClick={() => setSelected("Petite Section")}
                                className="w-[190px] justify-center bg-[#1AB2AD] hover:bg-[#11a096] hover:scale-105 hover:duration-500 text-white font-bold rounded border-b-2 shadow-md py-2 px-6 inline-flex items-center">
                                مستوى الإستئناس
                            </button>
                        </div>
                    </StyleRoot>
                    {selected === "Petite Section" ?
                        <>
                            <h1 className='gap-10 pt-20 text-4xl text-right text-white md:mx-6 xl:mx-60'>أنشطتي اليومية</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <ANCHITATI9 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI8 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI7 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Moyenne Section" ?
                        <>
                            <h1 className='gap-10 pt-20 text-4xl text-right text-white md:mx-6 xl:mx-60'>أنشطتي اليومية</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <ANCHITATI12 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI11 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI10 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="relative">
                                        <KORASSA1 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Grande Section" ?
                        <>
                            <h1 className='gap-10 pt-20 text-4xl text-right text-white md:mx-6 xl:mx-60'>أنشطتي اليومية</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <ANCHITATI15 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI14 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI13 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#00B0DE] rounded-full text-white text-xl font-bold pt-4">21,00<br></br>DHs</div>
                                    </div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="sm:hidden xl:block"></div>
                                    <div className="relative">
                                        <KORASSA2 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#EB8B2E] rounded-full text-white text-xl font-bold pt-4">25,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Histoires" ?
                        <>
                            <h1 className='gap-10 pt-20 text-4xl text-right text-white md:mx-6 xl:mx-60'>أنشطتي اليومية</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <ANCHITATI3 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI2 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI1 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI6 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI5 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <ANCHITATI4 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#1AB2AD] rounded-full text-white text-xl font-bold pt-4">9,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                    {selected === "Coloriage" ?
                        <>
                            <h1 className='gap-10 text-4xl text-right text-white md:mx-6 xl:mx-60'>المبدع الصغير</h1>
                            <StyleRoot style={styles.bounce}>
                                <div className="grid grid-cols-1 gap-10 py-24 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60">
                                    <div className="relative">
                                        <PN3 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN2 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN1 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN6 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN5 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN4 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN9 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN8 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN7 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                    <div className="relative">
                                        <PN10 />
                                        <div className="absolute -top-10 -right-6 border border-white border-1 w-20 h-20 bg-[#AFCA0B] rounded-full text-white text-xl font-bold pt-4">12,00<br></br>DHs</div>
                                    </div>
                                </div>
                            </StyleRoot>
                        </>
                        : null}
                </>
            }
        </>

    )
}