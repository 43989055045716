export default function Products() {
    return (
        <>
            <div>
                <h1 id="NosProduits" className="uppercase text-center text-4xl sm:text-5xl md:text-6xl xl:text-7xl mt-12">
                    edit consulting
                </h1>
                <img className="mx-auto mt-2" src="consulting-lines.png"></img>
            </div>
            {/* <div className="flex justify-center">

                <button className="text-white px-6 md:px-20 text-xl uppercase py-4 rounded-2xl bg-gradient-to-r from-red-400 to-[#E7513E] my-12">
                    Nos Produits
                </button>

            </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:mx-6 xl:mx-60 gap-10">
                <a href="/prescolaire">
                    <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                        <div className="max-w-sm">
                            <div className="bg-white rounded-full w-fit m-6 p-5">
                                <img className="" src="prescolaire.png" alt="" />
                            </div>
                            <div className="p-6">
                                <h5 className="text-gray-900 group-hover:text-white text-xl text-left font-medium transition duration-250 ease-in-out">Préscolaire</h5>
                                {/* <p className="text-gray-700 group-hover:text-white text-left text-base mb-4 transition duration-250 ease-in-out">
                                    edit consulting-edit consulting
                                    edit consulting-edit consulting
                                    edit consulting
                                </p> */}
                                <button type="button" className="text-left mt-20 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out">voir plus</button>
                            </div>

                        </div>
                    </div>
                </a>
                {/* <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <div className="max-w-sm">
                        <div className="bg-white rounded-full w-fit m-6 p-5">
                            <img className="" src="primaire.png" alt="" />
                            </div>
                        <div className="p-6">
                            <h5 className="text-gray-900 group-hover:text-white text-xl text-left font-medium transition duration-250 ease-in-out">Préscolaire</h5>
                            <p className="text-gray-700 group-hover:text-white text-left text-base mb-4 transition duration-250 ease-in-out">
                                edit consulting-edit consulting
                                edit consulting-edit consulting
                                edit consulting
                            </p>
                            <button type="button" className="text-left mt-20 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out">voir plus</button>
                        </div>
                        
                    </div>
                </div> */}
                <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <a href="/primaire">
                        <div className="max-w-sm">
                            <div className="bg-white rounded-full w-fit m-6 p-5">
                                <img className="" src="college-lycee.png" alt="" />
                            </div>
                            <div className="p-6">
                                <h5 className="text-gray-900 group-hover:text-white text-xl text-left font-medium transition duration-250 ease-in-out">Primaire</h5>
                                <button type="button" className="text-left mt-20 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out">voir plus</button>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <div className="max-w-sm">
                        <div className="bg-white rounded-full w-fit m-6 py-6 px-3">
                            <img className="" src="universitaire.png" alt="" />
                        </div>
                        <div className="p-6">
                            <h5 className="text-gray-900 group-hover:text-white text-xl text-left font-medium transition duration-250 ease-in-out">Universitaire</h5>
                            {/* <p className="text-gray-700 group-hover:text-white text-left text-base mb-4 transition duration-250 ease-in-out">
                                edit consulting-edit consulting
                                edit consulting-edit consulting
                                edit consulting
                            </p> */}
                            <button type="button" className="text-left mt-20 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out">voir plus</button>
                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}