import Header from "../Homepage/Header"
import Navbar from "../Homepage/Navbar"
import HTMLFlipBook from 'react-pageflip';
import Footer from "../Homepage/Footer";
import { useEffect, useRef } from "react";

export default function ANCHITATI13() {

    const book = useRef();


    function init(e) {
        book.current.pageFlip().turnToPage(book.current.pageFlip().getPageCount() - 1)
    }

    const LoadBook = () => {
        const book = [];
        for (let i = 80; i > 0; i--) {
            book.push(<div className="shadow-2xl" key={i}><img src={`/ANCHITATI13/projet_4_anchitati_56_page-${i.toString().padStart(4, "0")}.jpg`} width="700" height="800"></img></div>);
        }
        return book;
    };

    return (
        <>
            <div className="overflow-x-hidden">
                <Header />
                <div className="bg-center bg-cover bg-background px-14">
                    <Navbar />
                    <h1 className="mt-12 font-bold text-white text-7xl">أنشطتي اليومية</h1>
                    <h3 className="mt-3 text-4xl font-medium text-white">هيا أتعلم مع أصدقائي</h3>
                    <div className="flex justify-center w-full mt-12 mb-24">
                        <HTMLFlipBook ref={book} width={700} height={900} onInit={(e) => init(e)}
                        //showCover={true}
                        >
                            {LoadBook()}
                        </HTMLFlipBook>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}