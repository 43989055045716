import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

const ficheTechnique = [
    {
        id: 1,
        value: "Carte d'activité 1",
        arabic: false
    },
    {
        id: 2,
        value: "Carte d'activité 2",
        arabic: false
    },
    {
        id: 3,
        value: 'جدادات المستوى الاول',
        arabic: true
    },
    {
        id: 4,
        value: 'جدادات المستوى الثاني',
        arabic: true
    },
]

const dailyActivities = [
    {
        id: 1,
        value: "مذكرتي اليومية 5-4 سنوات",
    },
    {
        id: 2,
        value: "مذكرتي اليومية 5-6 سنوات",
    },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Resources() {

    const [selected, setSelected] = useState(ficheTechnique[3])
    const [selected2, setSelected2] = useState(dailyActivities[2])

    const changeHandler = (e) => {
        setSelected(e)
        window.location.href = `/${e.value}.pdf`
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center mt-12 xl:flex-row xl:ml-60 xl:gap-36">
                <div className="xl:w-6/12 md:w-10/12">
                    <h1 className="text-3xl font-bold text-left">Mes premières activités</h1>
                    {/* <button className="text-white px-6 md:px-20 text-xl uppercase py-4 rounded-2xl bg-gradient-to-r from-red-400 to-[#E7513E] my-12">
                        espace  Éducateur
                    </button> */}
                    <p className="w-11/12 ml-4 text-left md:ml-0">
                        Ce sont 2 nouveaux fascicules complémentaires de
                        «Mes premières activités»: le premier fascicule est dédié aux enfants du niveau 1 (4-5ans), et le 2ème fascicule est dédié aux enfants du niveau 2 (5-6 ans).   Ils constituent un outil pédagogique de renforcement et de soutien dans le domaine de la communication et de l’expression linguistique, la préparation à la lecture et à l’écriture, en forte corrélation avec les projets thématiques de la collection «Mes premières activités», conformément au cadre curriculaire national de l’enseignement préscolaire du ministère de l’éducation

                    </p>
                    {/* <button type="button" className="text-left mt-6 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out ml-6 md:ml-0">voir plus</button> */}

                </div>
                <div className="mt-12 lg:mr-36">
                    <img className="w-[35rem]" src="book.png"></img>

                </div>

            </div>
            <div className="flex flex-col-reverse items-center justify-center lg:flex-col xl:flex-row xl:ml-60 xl:gap-36">
                <div className="">
                    <img className="w-[35rem]" src="book.png"></img>

                </div>

                <div className="xl:w-1/2 lg:pr-36 md:w-10/12">
                    <h1 className="text-3xl font-bold text-right">أنشطتي اليومية في القراءة و الكتابة</h1>
                    <p className="mr-4 text-right md:mr-0 md:ml-0">
                        هي عبارة عن 2 كراسات تربوية مكملة وداعمة لأطفال مرحلة التعليم الأولي في مجال أنشطة الإعداد للقراءة والتخطيط و الكتابة: كراسة خاصة بالمستوى الأول (4-5 سنوات) و كراسة أخرى خاصة بالمستوى الثاني (5-6 سنوات)، و ذلك في ارتباط وثيق مع مشاريع المجموعة التربوية  “أنشطتي اليومية” وفق الاطار المنهاجي الوطني للتعليم الأولي لوزارة التربية الوطني.
                    </p>
                    {/* <button type="button" className="text-left mt-6 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out ml-6 md:ml-0">voir plus</button> */}

                </div>

            </div>
            <div className="flex flex-col items-center justify-center mt-12 xl:flex-row xl:ml-60 xl:gap-36">
                <div className="xl:w-6/12 md:w-10/12">
                    <h1 className="text-3xl font-bold text-left">A moi d’écrire </h1>
                    {/* <button className="text-white px-6 md:px-20 text-xl uppercase py-4 rounded-2xl bg-gradient-to-r from-red-400 to-[#E7513E] my-12">
                        espace  Éducateur
                    </button> */}
                    <p className="w-11/12 ml-4 text-left md:ml-0">
                        C’est un seul fascicule complémentaire de «Mes premières activités» qui vise le renforcement et le soutien des enfants des deux niveaux préscolaires en matière d’acquisition d’une approche méthodique  pour le graphisme, l’écriture et la lecture des lettres, des mots et des chiffres, en parfaite harmonie avec les projets pédagogiques de la collection «Mes premières activités»
                    </p>
                    {/* <button type="button" className="text-left mt-6 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out ml-6 md:ml-0">voir plus</button> */}

                </div>
                <div className="mt-12 lg:mr-36">
                    <img className="w-[35rem]" src="news.png"></img>

                </div>

            </div>
            <div className="flex flex-col-reverse items-center justify-center lg:flex-col xl:flex-row xl:ml-60 xl:gap-36">
                <div className="">
                    <img className="w-[35rem]" src="haya.jpeg"></img>

                </div>

                <div className="xl:w-1/2 lg:pr-36 md:w-10/12">
                    <h1 className="text-3xl font-bold text-right">أنشطتي اليومية: هيا نكتب</h1>
                    <p className="mr-4 text-right md:mr-0 md:ml-0">
                        هي  كراسة تربوية واحدة مكملة وداعمة لأطفال المستوى الأول (4-5 سنوات) و أطفال المستوى الثاني (5-6 سنوات)، في مجال الأنشطة المنهجية الدقيقة للتعلم التدريجي لتخطيط و كتابة الحروف و الكلمات و الأعداد و تهجيها  وفق مشاريع المجموعة التربوية  “أنشطتي اليومية”   </p>
                    {/* <button type="button" className="text-left mt-6 w-full inline-block text-[#E7513E] font-medium text-xs leading-tight uppercase group-hover:text-white  focus:outline-none focus:ring-0 transition duration-250 ease-in-out ml-6 md:ml-0">voir plus</button> */}

                </div>

            </div>

            <div className="flex flex-col-reverse items-center justify-center gap-2 mx-2 lg:flex-row lg:mx-6 md:gap-5 xl:gap-10">
                <button className="text-white w-[30rem] text-sm md:text-base xl:text-xl uppercase py-3 md:py-6 rounded-2xl my-12 left-0 bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <a
                        href="/espaceeducateur">
                        ESPACE ÉDUCATEUR
                    </a>
                </button>
                <button className="text-white w-[30rem] text-sm md:text-base xl:text-xl uppercase py-3 md:py-6 rounded-2xl my-12 left-0 bg-[#7F7F7F] transition duration-700 ease-in-out hover:scale-105">
                    <a
                        href="nrp.pdf">
                        RESSOURCES PÉDAGOGIQUES
                    </a>
                </button>
                {/* <Listbox value={selected} onChange={(e) => { changeHandler(e) }}>
                    {({ open }) => (
                        <>
                            <div className="text-white w-full lg:w-[36rem] h-[80px] text-xs md:text-base xl:text-xl uppercase rounded-2xl my-6 left-0 bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105 relative">
                                <Listbox.Button className="text-white w-full lg:w-[31rem] text-sm md:text-base xl:text-xl h-12 uppercase rounded-2xl py-6 left-0 bg-[#E7513E] transition duration-700 ease-in-out">
                                    <span className="flex items-center justify-center">
                                        <span className="block -ml-4 text-base truncate lg:text-2xl">{"Téléchargement fiches techniques تحميل" || selected.value}</span>
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                        <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {ficheTechnique.map((item) => (
                                            <Listbox.Option
                                                key={item.id}
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? 'text-white bg-[#E7513E]' : 'text-gray-900',
                                                        item.arabic ? 'flex justify-end' : 'flex justify-start',
                                                        'relative w-full text-right cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={item}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                                {item.value}
                                                            </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-[#E7513E]',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
                <Listbox value={selected2} onChange={(e) => changeHandler(e)}>
                    {({ open }) => (
                        <>
                            <div className="text-white w-full lg:w-[30rem] h-[80px] text-sm md:text-base xl:text-xl uppercase rounded-2xl my-6 left-0 bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105 relative">
                                <Listbox.Button className="text-white w-full lg:w-[30rem] text-sm md:text-base xl:text-xl h-12 uppercase rounded-2xl py-6 left-0 bg-[#E7513E] transition duration-700 ease-in-out">
                                    <span className="flex items-center justify-center">
                                        <span className="block ml-4 mr-10 text-base truncate lg:text-2xl">{"Téléchargement تحميل المذكرة اليومية" || selected2.value}</span>
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                        <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {dailyActivities.map((item) => (
                                            <Listbox.Option
                                                key={item.id}
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? 'text-white bg-[#E7513E]' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={item}
                                            >
                                                {({ selected2, active }) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            <span
                                                                className={classNames(selected2 ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                                {item.value}
                                                            </span>
                                                        </div>

                                                        {selected2 ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-[#E7513E]',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox> */}

            </div>
        </>
    )
} 