import Header from "../Homepage/Header";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";


const QuiSommesNous = () => {
    return (
        <div className="overflow-x-hidden">
            <Header />
            <div className="bg-background bg-cover bg-center px-14">
                <Navbar />
                <div className="w-11/12 xl:w-2/4 text-white pt-36 font-bold h-[1000px] text-left mx-auto">
                    <div className="gradiant rouded rounded-md p-12 shadow-2xl">
                        <p className="text-xl md:text-2xl lg:text-3xl xl:text-6xl 2xl:6xl">
                            QUI SOMMES-NOUS?
                        </p>
                        <p className="text-sm md:text-base font-medium mt-3 lg:text-lg xl:text-3xl">
                            Fondée en 1992, la Société EDIT CONSULTING, a réussi à capitaliser des expériences et des savoir faires qui en font aujourd'hui une maison d'édition spécialisée dans le domaine du livre scolaire et universitaire et une agence conseil en communication et formation.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default QuiSommesNous;