import Header from "../Homepage/Header"
import Navbar from "../Homepage/Navbar"
import HTMLFlipBook from 'react-pageflip';
import Footer from "../Homepage/Footer";

export default function CAMPA56() {

    const LoadBook = () => {
        const book = [];
        for (let i = 1; i < 65; i++) {
            book.push(<div className="shadow-2xl" key={i}><img src={`/CAMPA56/cahier_d'activité_MPA_5_6_page-${i.toString().padStart(4, "0")}.jpg`} width="700" height="800"></img></div>);
        }
        return book;
    };

    return (
        <>
            <div className="overflow-x-hidden">
                <Header />
                <div className="bg-background bg-cover bg-center px-14">
                    <Navbar />
                    <h1 className="text-6xl font-bold text-white mt-12">Mes premières activités</h1>
                    <h3 className="text-3xl font-medium mt-3 text-white">le cahier d'activités niveau 2</h3>
                    <div className="flex w-full justify-center mt-12 mb-24">
                        <HTMLFlipBook width={700} height={886}
                        //showCover={true}
                        >
                            {LoadBook()}
                        </HTMLFlipBook>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}