import Header from "../Homepage/Header"
import Navbar from "../Homepage/Navbar"
import HTMLFlipBook from 'react-pageflip';
import Footer from "../Homepage/Footer";
import { useEffect, useRef } from "react";

export default function ANCHITATI2() {

    const book = useRef();


    function init(e) {
        book.current.pageFlip().turnToPage(book.current.pageFlip().getPageCount() - 1)
    }

    const LoadBook = () => {
        const book = [];
        for (let i = 16; i > 0; i--) {
            book.push(<div className="shadow-2xl" key={i}><img src={`/ANCHITATI2/histoire_anchitati_p2_page-${i.toString().padStart(4, "0")}.jpg`} width="700" height="800"></img></div>);
        }
        return book;
    };

    return (
        <>
            <div className="overflow-x-hidden">
                <Header />
                <div className="bg-background bg-cover bg-center px-14">
                    <Navbar />
                    <h1 className="text-7xl font-bold text-white mt-12">أنشطتي اليومية</h1>
                    <h3 className="text-4xl font-medium mt-3 text-white">أسرتي وبيتي</h3>
                    <div className="flex w-full justify-center mt-12 mb-24">
                        <HTMLFlipBook ref={book} width={700} height={700} onInit={(e) => init(e)}
                        //showCover={true}
                        >
                            {LoadBook()}
                        </HTMLFlipBook>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}