import Navbar from "../Homepage/Navbar"
import Header from "../Homepage/Header"
import ProductsList from "./ProductsList"
import Footer from "../Homepage/Footer"

export default function Primaire() {
    return (
        <>
            <div className="overflow-x-hidden">
                <Header />
                <div className="bg-background bg-fixed bg-top px-14">
                    <Navbar />
                    <ProductsList />
                </div>
                <Footer />
            </div>
        </>
    )

}
