import Navbar from "./Navbar"
import Video from "./Video"
import Products from "./Products"
import Resources from "./Resources"
import Footer from "./Footer"
import Header from "./Header"

export default function Homepage() {
    return (
        <div className="overflow-x-hidden">
            <Header />
            <div className="bg-background bg-cover bg-center px-14">
                <Navbar />
                <Video />
                <Products />
            </div>
            <Resources />
            <Footer />
        </div>
    )
}