import Header from "../../Homepage/Header"
import Navbar from "../../Homepage/Navbar"
import HTMLFlipBook from 'react-pageflip';
import Footer from "../../Homepage/Footer";
import { useRef } from "react";

export default function Math1() {

    const book = useRef();


    // function init(e) {
    //     book.current.pageFlip().turnToPage(book.current.pageFlip().getPageCount() - 1)
    // }

    const LoadBook = () => {
        const book = [];
        for (let i = 1; i < 113; i++) {
            book.push(<div className="shadow-2xl" key={i}><img src={`/Math1/MAth+_CP1024_${i.toString()}.jpg`} width="700" height="800"></img></div>);
        }
        return book;
    };

    return (
        <>
            <div className="overflow-x-hidden">
                <Header />
                <div className="bg-background bg-cover bg-center px-14">
                    <Navbar />
                    <h1 className="text-7xl font-bold text-gray-100 mt-12">CP MATHS+</h1>
                    <div className="flex w-full justify-center mt-12 mb-24">
                        <HTMLFlipBook width={700} height={900} ref={book}//showCover={true}
                        >
                            {LoadBook()}
                        </HTMLFlipBook>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}