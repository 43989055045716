
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'


export default function Navbar() {


  const navigation = [
    { name: 'ACCUIEIL', href: '/', current: false },
    { name: 'QUI SOMMES-NOUS', href: '/QuiSommesNous', current: false },
    { name: 'NOS PRODUITS', href: '/prescolaire', current: false },
    { name: 'ESPACE ÉDUCATEUR', href: '/EspaceEducateur', current: false },
    { name: 'CONTACTEZ NOUS', href: 'mailto:edit.22@editconsulting.ma', current: false }
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (

    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="py-8 xl:py-20 max-w-7xl mx-auto px-2 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center xl:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex md:flex-col xl:flex-row items-center gap-36 justify-center xl:items-stretch xl:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a href="/">
                    <img
                      href="/"
                      className="block lg:hidden relative w-24 mx-12 xl:mx-0 xl:w-38 xl:w-52"
                      src="/logo_edit_consulting.png"
                      alt="edit_consulting"
                    />
                  </a>
                  <a href="/">
                    <img
                      className="hidden lg:block relative w-24 mx-12 xl:mx-0 xl:w-38 xl:w-52 xl:mt-4"
                      src="/logo_edit_consulting.png"
                      alt="edit_consulting"
                    />
                  </a>
                </div>
                <div className="hidden xl:block ml:ml-6 w-screen xl:ml-12">
                  <div className="flex space-x-4 mt-12">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'text-white' : 'text-white hover:bg-red-600',
                          'px-2 mt-4 py-2 rounded-md text-xs xl:xl:text-lg font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xl:hidden">
            <div className="px-2 mx-20 mt-6 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'text-white' : 'text-white hover:bg-red-600',
                    'block px-3 py-2 rounded-md text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>





  )
}