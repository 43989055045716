import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';

export default function ANCHITATI3() {


    const [isFlipped, setIsFlipped] = useState(false);

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <a href="anchitati3" onMouseEnter={() => setIsFlipped((prev) => !prev)}>
                <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <div className="bg-white w-full">
                        <img className="rounded-lg" src="./ANCHITATI3/front.jpg" alt="" />
                    </div>
                </div>
            </a>
            <a href="anchitati3" onMouseLeave={() => setIsFlipped((prev) => !prev)}>
                <div className="bg-white md:bg-transparent flex justify-start items-start shadow-lg rounded-lg group hover:bg-[#E7513E] transition duration-700 ease-in-out hover:scale-105">
                    <div className="bg-white w-fit">
                        <img className="rounded-lg" src="./ANCHITATI3/front.jpg" alt="" />
                    </div>
                </div>
            </a>
        </ReactCardFlip>
    )
}